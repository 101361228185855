import React, { useState, Fragment } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import Button from "./Button"
import Captcha from "./Captcha"
import styles from "./utils/elements.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

const ActionButtons = ({ hasCaptcha, submit, next, back }) => {
  const [isCaptchaNotSolved, setIsCaptchaNotSolved] = useState(true)

  return (
    <Fragment>
      {hasCaptcha && <Captcha setIsCaptchaNotSolved={setIsCaptchaNotSolved} />}
      <div className={classNames("buttons is-centered", styles["buttons"])}>
        {back && (
          <Button
            onClick={() => {
              if (back.callback) {
                back.callback()
                return
              }
              navigate(back.link)
            }}
            className={classNames(
              "is-size-6 is-outlined mr-1 mr-1-mobile",
              back.className,
              styles["button__secondary"]
            )}
            size="medium"
            color="secondary"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
        )}

        {next && (
          <Button
            onClick={() => {
              if (next.callback) next.callback()

              navigate(next.link)
            }}
            className={classNames(
              "is-size-6 has-text-weight-bold mr-1 mr-1-mobile",
              next.className,
              styles["button"]
            )}
            size="medium"
            color="secondary"
            isDisabled={next.disabled}
          >
            {next.label}
          </Button>
        )}

        {submit && (
          <Button
            className={classNames(
              "is-size-6 has-text-weight-bold mr-1 mr-1-mobile",
              submit.className,
              styles["button"]
            )}
            size="medium"
            color="secondary"
            type="submit"
            isLoading={submit.loading}
            isDisabled={submit.disabled || (!!hasCaptcha && isCaptchaNotSolved)}
          >
            {submit.label}
          </Button>
        )}
      </div>
    </Fragment>
  )
}

export default ActionButtons
